<template>
    <footer>
        <div class="container">
            <ul class="footer_link_group clear">
                <li class="footer_link" v-for="(footerLink, index) in footerLinkList" :key="index">
                    <a :href="footerLink.url" target="_blank" rel="noopener">
                        {{ footerLink.title }}
                    </a>
                </li>
            </ul>
            <p class="copy_right">
                Copyright © {{ new Date() | moment("YYYY") }} KKBOX All Rights Reserved.
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
    data() {
        return {
            footerLinkList: [
                {
                    title: 'KKBOX 首頁',
                    url: 'https://www.kkbox.com/tw/tc/',
                },
                {
                    title: '會員中心',
                    url: 'https://ssl.kkbox.com/member.php',
                },
                {
                    title: '服務中心',
                    url: 'https://help.kkbox.com/tw/zh-tw',
                },
            ],
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/scss/_mixins";

footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 60px 0 115px;
    background: #10314c;
    color: #fff;
    font-size: 14px;
    font-weight: 100;
    ul.footer_link_group {
        margin-left: 75px;
        margin-bottom: 10px;
        @include for-md-mobile {
            margin-left: 0;
        }
        li.footer_link {
            float: left;
            border-right: 0.5px solid #fff;
            padding: 0 24px 0;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                border-right: none;
            }
            &:hover {
                color: #bcbcbc;
            }
            @include for-md-mobile {
                padding: 0 16px 0;
            }
        }
    }
    .copy_right {
        margin-left: 75px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        @include for-md-mobile {
            margin-left: 0;
        }
    }
    @include for-md-mobile {
        padding: 60px 17px 30px;
    }
    @include for-xs-mobile {
        padding: 60px 16px 30px;
    }
}

</style>
