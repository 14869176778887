<template>
    <div class="info">
        <Billboard view="info_view" />
        <div class="QA_container">
            <div class="title_block clear">
                <span class="blue_line"></span>
                <h2 class="title">點數說明及常見問題</h2>
                <span class="blue_line"></span>
            </div>
            <!-- eslint-disable max-len -->
            <h4 class="subtitle">點數說明</h4>
            <div class="QA_title" :class="{open: QA === 1}">
                <div class="question" @click.stop="toggleQA(1)">
                    <h6>什麼是 KK Points</h6>
                    <span>{{QA === 1 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    KK Points 是由願境網訊股份有限公司（下稱本公司）、本公司關係企業或本公司合作廠商所提供的 KK Points（下稱點數）回饋服務。
                    本公司將依照「KK Points 發放規則」發送點數予使用者，使用者可以使用點數兌換指定商品、服務或參加活動。
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 2}">
                <div class="question" @click.stop="toggleQA(2)">
                    <h6>取得 KK Points</h6>
                    <span>{{QA === 2 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    使用者須先於本公司服務 上註冊一組台灣地區帳號（下稱帳號），如已有帳號者，則無需註冊。
                    本公司將根據該組帳號透過「KK Points 發放規則」可取得之點數，發送點數予使用者。
                    您可以前往了解更多關於<a href="https://ssl.kkbox.com/tw/membership/account_info.php" target="_blank">『KK帳號』</a>。
                    您可以至<a href="https://kkpoints.kkbox.com/record" target="_blank"> 點數中心 </a> 查詢您的點數累積及使用明細。
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 4}">
                <div class="question" @click.stop="toggleQA(4)">
                    <h6>點數歸戶時間</h6>
                    <span>{{QA === 4 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    (1) 訂閱 KKBOX 標準音質 / 無損音質單人方案 / KKBOX + KKTV / 家庭方案所取得點數：<br>
                    訂閱首月：付款後的 10 個工作天內，會進行首次點數歸戶。<br>
                    後續月份：之後每月將確認該帳號於該月份所取得之點數，並將點數歸戶。<br>
                    (2) 訂閱 KKBOX 家庭方案所取得點數：<br>
                    訂閱首月：若為既有付費會員訂購家庭方案，則會延續原給點週期，於下次週期給予點數。若為新購買會員，則會在付款後的 10 個工作天內，會進行首次點數歸戶。<br>
                    後續月份：之後每月將確認該帳號於該月份所取得之點數，並將點數歸戶。<br>
                    (3) 加入 KKBOX 家庭方案成員所取得點數：<br>
                    加入首月：若為既有付費會員加入成為家庭方案成員，則會延續原給點週期，於下次週期給予點數。若為新會員加入家庭成員，則會跟隨家庭方案主帳號給點週期，於下個給點日給予點數。<br>
                    後續月份：之後每月將確認該帳號於該月份所取得之點數，並將點數歸戶。<br>
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 5}">
                <div class="question" @click.stop="toggleQA(5)">
                    <h6>點數扣回</h6>
                    <span>{{QA === 5 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    若使用者於點數歸戶後，退訂 KKBOX / KKBOX + KKTV 服務/ 辦理 KKTIX 票券退票，則本公司將扣回該等點數。如使用者帳戶之點數不足扣回，帳戶內之點數紀錄將為負值。日後使用者獲得之點數將優先用以抵充該負值。
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 6}">
                <div class="question" @click.stop="toggleQA(6)">
                    <h6>點數之效期</h6>
                    <span>{{QA === 6 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    點數歸戶當日起至後年的 12 月 31 日到期。如因特殊活動所獲得之點數，其效期將依該活動規範為準。<br>
                    例：2019/11/30 得到 30 點，該批點數將於 2021/12/31 失效。<br>
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 7}">
                <div class="question" @click.stop="toggleQA(7)">
                    <h6>點數的使用方法</h6>
                    <span>{{QA === 7 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    KK Points 可適用之活動可參照 <a href="https://kkpoints.kkbox.com/event" target="_blank"> 點數中心 - 活動列表 </a>，本公司將隨時更新。
                    使用 KK Points 的詳細條件，例如取得活動參與資格或兌換商品等所需之點數數量、使用條件及兌換程序等，將視各兌換活動而有所不同。
                    即使使用者完成點數兌換，取得參與特定活動之資格，若經本公司或該活動之合作夥伴認定該點數兌換係以不當方式執行，則本公司或合作夥伴得決定取消活動參與資格，且不返還點數。
                    除另有約定外，本公司與各合作廠商間並無合夥、經銷、代理或保證人關係，使用者兌換之活動參與資格、商品或服務，由各合作廠商提供使用者。其寄送、使用、保證、瑕疵擔保等問題，均由各合作廠商負責之。
    使用者以點數兌換商品等而應繳納任何稅捐時，由使用者自行負擔該筆稅款，並應配合本公司及合作廠商辦理相關作業。
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 8}">
                <div class="question" @click.stop="toggleQA(8)">
                    <h6>點數使用限制</h6>
                    <span>{{QA === 8 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                點數不得移轉，使用者亦不得要求兌換現金，亦不得私下交易，亦不得合併。
                除專案活動規則明訂外，KK Points 一經使用，便不可退還。
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 9}">
                <div class="question" @click.stop="toggleQA(9)">
                    <h6>資料授權</h6>
                    <span>{{QA === 9 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    您同意本公司得將您的姓名、電話、地址、消費時間、消費明細、點數資料等，提供各合作廠商，作為合作廠商累兌點作業、點數餘額通知、商品或服務之提供、統計調查分析、交叉比對、行銷與其他服務之使用。
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 10}">
                <div class="question" @click.stop="toggleQA(10)">
                    <h6>本公司保留權利</h6>
                    <span>{{QA === 10 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    本公司保留修改 KK Points 發送、使用規則及終止點數相關機制、活動的權利。相關變更將公布於本公司之官方網站。變更後，如您仍繼續使用 KK Points 服務，視同同意該等變更。
                </div>
            </div>
            <h4 class="subtitle">常見問題</h4>
            <div class="QA_title" :class="{open: QA === 11}">
                <div class="question" @click.stop="toggleQA(11)">
                    <h6>KK Points 可折抵我在 KKBOX (或其關係企業或其合作廠商)內服務所消費的金額嗎？</h6>
                    <span>{{QA === 11 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    目前 KK Points 的使用僅限參加「點數中心」-> 「活動列表」的點數活動，您可以 KK Points 換取活動抽獎券、參加贈票活動，或購買優惠價演唱會門票資格等，恕無法直接折抵消費。您可關注此點數中心掌握最新 KK Points 活動消息。
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 12}">
                <div class="question" @click.stop="toggleQA(12)">
                    <h6>如何確認我的 KK Points 有效期限？</h6>
                    <span >{{QA === 12 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    一般為點數歸戶當日起，至後年的 12 月 31 日到期。因特殊活動所獲得的點數效期將依活動規範為準。您可至點數中心登入您的 帳號，進入<a href="https://kkpoints.kkbox.com/record" target="_blank">「查詢點數」</a>頁面，累積點數列表將顯示點數的效期資訊。
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 13}">
                <div class="question" @click.stop="toggleQA(13)">
                    <h6>KK Points 扣除的順序是什麼？</h6>
                    <span>{{QA === 13 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    將採取先進先出的方式，即較早獲得的點數會被優先扣除。
                </div>
            </div>
             <div class="QA_title" :class="{open: QA === 14}">
                <div class="question" @click.stop="toggleQA(14)">
                    <h6>換了手機之後，我的 KK Points 會消失嗎？</h6>
                    <span>{{QA === 14 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    KK Points 的點數資訊皆儲存在您的帳號內，不會因為換手機而點數遺失。
                </div>
            </div>
            <div class="QA_title" :class="{open: QA === 15}">
                <div class="question" @click.stop="toggleQA(15)">
                    <h6>過去的消費紀錄是否可以換成點數？</h6>
                    <span>{{QA === 15 ? '-' : '+'}}</span>
                </div>
                <hr />
                <div class="answer">
                    KK Points 為 2019 年 11 月新開啟之點數回饋服務，將不會比照發放規則，溯及過去消費記錄而補發點數。但針對過去 KKBOX 的忠誠用戶，我們將發放一次性會員獎勵點數。發放點數資訊請至<a href="https://kkpoints.kkbox.com/record" target="_blank"> 點數中心 </a>登入查看。
                </div>
            </div>
        </div>
        <div class="kkpoints_notices">
            <div class="kkpoints_notices_group">
                <p>KK Points 點數累積注意事項</p>
                <ul>
                    <li>KK Points 除活動規則明訂，一經使用便不可退還。</li>
                    <li>KKBOX 保留修改點數兌換規則、使用規則及終止點數相關機制、活動的權利。</li>
                    <li>KK Points 目前無點數交易機制，不負責用戶間的私下交易糾紛。</li>
                </ul>
            </div>
        </div>
    </div>
    <!-- eslint-disable max-len -->
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import Billboard from '@/components/Billboard.vue';

export default {
    name: 'info',
    components: {
        Billboard,
    },
    data() {
        return {
            openCard: null,
            QA: null,
        };
    },
    methods: {
        toggleCard(s) {
            if (this.openCard === s) {
                this.openCard = null;
            } else {
                this.openCard = s;
            }
        },
        toggleQA(n) {
            if (this.QA === n) {
                this.QA = null;
            } else {
                this.QA = n;
            }
        },
    },
    computed: mapState([
        'isLogin',
    ]),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/scss/_mixins";
.info {
    .QA_container {
        max-width: 716px;
        margin: 0 auto;
        .title_block {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 50px 0 0;
            h2.title {
                letter-spacing: 2.5px;
                margin: 0 15px;
                text-align: center;
                color: #00aed8;
                font-size: 28px;
                font-weight: 300;
                position: relative;
                @include for-mobile {
                     font-size: 26px;
                }
                img {
                    position: absolute;
                    &.kkpoints_coin_s {
                        width: 12px;
                        bottom: -10px;
                        left: 30%;
                    }
                    &.kkpoints_coin_m {
                        width: 20px;
                        top: -15px;
                        left: -10px;
                    }
                    &.kkpoints_coin_l {
                        width: 32px;
                        top: -25px;
                        right: -25px;
                    }
                }
            }
            .blue_line {
                border-bottom: 1px solid #00aed8;
                flex: 1;
            }
        }
        ul.exit_preview_text {
            list-style-type: disc;
            li {
                padding: 10px 0;
                a {
                    color:blue;
                    text-decoration: underline;
                }
                span.red {
                    color:red;
                }
            }
        }
        .bu_card_group {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 100px;
            @include for-mobile {
              margin-bottom: 80px;
            }
            .bu_card {
                width: 343px;
                height: 206px;
                border-radius: 10px;
                box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
                position: relative;
                margin: 70px 0 0;
                transition: transform .5s, box-shadow .5s, height .5s;
                &:hover {
                    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3);
                    transform: translateY(-10px);
                }
                @include for-mobile {
                  margin: 50px 0 0;
                }
                .bu_icon {
                    width: 50px;
                    position: absolute;
                    top: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                    box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.2);
                    border-radius: 11.5px;
                }
                .bu_title {
                    text-align: center;
                    color: #3a3a3a;
                    line-height: 1.25;
                    font-size: 22px;
                    font-weight: bold;
                    padding-top: 45px;
                    @include for-xs-mobile {
                        font-size: 18px;
                    }
                }
                .bu_card_content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    img.kkpoints_coin {
                        width: 25px;
                        height: 25px;
                        margin: 0 5px;
                    }
                    p.kkpoints_number {
                        color: #00aed8;
                        line-height: 1;
                        text-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
                        font-size: 55px;
                        font-weight: bold;
                        margin: 0 5px;
                    }
                    .kkpoints_desc {
                        display: flex;
                        flex-direction: column;
                        margin: 0 5px;
                        small {
                            color: #00aed8;
                            line-height: 1;
                            font-size: 12px;
                        }
                        p {
                            color: #00aed8;
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }
                }
                .kkpoints_rules {
                    margin-bottom: 80px;
                    .table_header {
                        text-align: center;
                        color: #333333;
                        font-size: 12px;
                        font-weight: 300;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        border-bottom: 1px solid #4d4d5087;
                        padding: 10px 0px;
                        margin: 30px 5px 0;
                        .table_item {
                            flex: 1;
                        }
                        @include for-sm-mobile {
                            padding: 10px 5px;
                        }
                    }
                    .table_body {
                        text-align: center;
                        color: #00aed8;
                        font-size: 13px;
                        font-weight: bold;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        border-bottom: 1px dashed #4d4d5087;
                        padding: 10px 0px;
                        margin: 0px 5px;
                        .table_item {
                            flex: 1;
                            position: relative;
                        }
                        @include for-sm-mobile {
                            padding: 10px 5px;
                        }
                        &.gray {
                              color: rgba(51,51,51,.6);
                        }
                    }
                    .table_footer {
                        text-align: center;
                        color: rgba(51, 51, 51, 0.6);
                        font-size: 13px;
                        font-weight: bold;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        border-bottom: 1px solid #4d4d5087;
                        padding: 10px 0px;
                        margin: 0px 5px 0px;
                        .table_item {
                            flex: 1;
                        }
                        @include for-sm-mobile {
                            padding: 10px 5px;
                        }
                    }
                }
                &.not_allow {
                    transition: none;
                    box-shadow: 0 4px 14px 0 rgba(0,0,0,.2);
                    transform: none;
                    .card_bottom {
                        cursor: auto;
                        p {
                            color: rgba(51, 51, 51, 0.6);
                        }
                    }
                }
                .card_bottom {
                    display: flex;
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0 0 10px 10px;
                    border-top: 1px solid #e9e9e9;
                    height: 43px;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    background-color: #fff;
                    p {
                        line-height: 1;
                        text-align: center;
                        color: #333333;
                        font-size: 12px;
                        margin-right: 8px;
                    }
                    .arrow_down, .arrow_up {
                        width: 16px;
                    }
                }
                .kkpoints_rules {
                    height: 0;
                    overflow: hidden;
                    transition: height .5s;
                }
                .arrow_up, .close_rules {
                    display: none;
                }
                &.open_card {
                    height: 387px;
                    .kkpoints_rules {
                        height: 197px;
                    }
                    .arrow_up, .close_rules {
                        display: block;
                    }
                    .arrow_down, .open_rules {
                        display: none;
                    }
                }
                &.prime_card {
                    .table_item {
                        // 星星小圖的位置
                        img {
                            position: absolute;
                            &:first-child {
                                bottom: -5px;
                                width: 7px;
                                left: 13px;
                            }
                            &:last-child {
                                top: -10px;
                                width: 10px;
                                right: auto;
                            }
                        }
                    }
                    // 打開卡片高與內容高
                    &.open_card {
                        height: 428px;
                        .kkpoints_rules {
                         height: 275px;
                        }
                        @include for-xs-mobile {
                            height: 478px;
                        }
                    }
                }
                &.kkbox_card {
                    .table_item {
                        // 星星小圖的位置
                        img {
                            position: absolute;
                            &:first-child {
                                bottom: -5px;
                                width: 7px;
                                left: 13px;
                            }
                            &:last-child {
                                top: -10px;
                                width: 10px;
                                right: auto;
                            }
                        }
                    }
                    // 打開卡片高與內容高
                    &.open_card {
                        height: 470px;
                        .kkpoints_rules {
                         height: 275px;
                        }
                    }
                }
                &.kktix_card {
                    .table_item {
                        // 星星小圖的位置
                        img {
                            position: absolute;
                            &:first-child {
                                bottom: -5px;
                                width: 7px;
                                left: 25px;
                            }
                            &:last-child {
                                top: -10px;
                                width: 10px;
                                right: auto;
                            }
                        }
                    }
                    // 打開卡片高與內容高
                    &.open_card {
                        height: 428px;
                        .kkpoints_rules {
                         height: 275px;
                        }
                        @include for-xs-mobile {
                            height: 478px;
                        }
                    }
                }
                .small_notice {
                    margin: 5px 0;
                    transform: scale(0.8);
                    small {
                        font-size: 12px;
                        line-height: 1;
                        color: rgba(51,51,51,.6);
                        margin: 0;
                    }
                }
            }
            @include for-mobile {
                justify-content: center;
            }
        }
        h4.subtitle {
            color: #3a3a3a;
            line-height: 1.5;
            font-size: 20px;
            font-weight: bold;
            text-align: left;
            border-left: 4px solid #00aed8;
            padding-left: 15px;
            margin: 60px 0 10px 15px;
            @include for-mobile {
                margin-top: 50px;
                margin-left: 0;
            }
            @include for-sm-mobile {
                font-size: 16px;
            }
        }
        .QA_title {
            display: flex;
            border-bottom: 1px solid rgba(204, 204, 204, 0.3);
            flex-direction: column;
            &:last-child {
                margin-bottom: 100px;
            }
            .question {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                &:hover {
                   background-color: #fafafa;
                }
                h6 {
                    color: #333333;
                    font-size: 18px;
                    font-weight: 500;
                    margin: 22px 30px;
                    @include for-mobile {
                         margin: 20px;
                    }
                    @include for-sm-mobile {
                        font-size: 14px;
                    }
                }
                span {
                    color: #333333;
                    font-size: 28px;
                    cursor: pointer;
                    margin: 0 20px;
                }
            }
            hr {
                border-top: 1px solid rgba(204, 204, 204, 0.3);
                width: 100%;
                border-left: none;
                border-bottom: none;
                border-right: none;
                margin: 0;
                display: none;
            }
            .answer {
                color: #333333;
                line-height: 1.63;
                font-size: 16px;
                font-weight: 300;
                margin: 20px 68px 30px 30px;
                display: none;
                ul {
                    list-style-type: disc;
                    padding-left: 20px;
                }
                a {
                    color: #00aed8;
                }
                @include for-mobile {
                    margin-right: 56px;
                    margin-left: 20px;
                }
                @include for-sm-mobile {
                    font-size: 14px;
                }
            }
            &.open {
                .answer, hr {
                    display: block;
                }
            }
        }
        @include for-mobile {
            padding: 0 16px;
        }
    }
    a.contact_us {
        width: 124px;
        height: 44px;
        border-radius: 22px;
        border: solid 2px #00aed8;
        color: #00aed8;
        font-size: 16px;
        font-weight: bold;
        display: block;
        margin: 30px auto 100px;
        cursor: pointer;
        background-color: #ffffff;
        text-align: center;
        line-height: 40px;
        transition: box-shadow .5s;
        &:hover {
             box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
        }
        @include for-mobile {
           margin-bottom: 50px;
        }
    }
    .kkpoints_notices {
        height: 224px;
        background-color: #eeeeee;
        display: flex;
        justify-content: center;
        padding: 40px 0 0;
        .kkpoints_notices_group {
            p {
                color: #333333;
                line-height: 2em;
                font-size: 18px;
                margin-bottom: 10px;
                @include for-mobile {
                    font-size: 14px;
                }
            }
            ul {
                list-style-type: disc;
                padding-left: 40px;
                li {
                    color: #333333;
                    line-height: 2em;
                    font-size: 16px;
                    font-weight: 300;
                    @include for-mobile {
                        font-size: 12px;
                    }
                }
            }
        }
        @include for-mobile {
            padding: 20px 16px 30px;
        }
    }
}
</style>
