import moment from 'moment';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSwal from 'vue-swal';
import InfiniteLoading from 'vue-infinite-loading';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Clipboard from 'v-clipboard';
import App from './App.vue';
import router from './router';
import store from './store';

library.add(faCopy);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.prototype.moment = moment;

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

Vue.use(VueSwal);

Vue.use(require('vue-moment'));

Vue.use(InfiniteLoading);

Vue.use(Clipboard);

router.beforeEach((to, from, next) => {
    const isLogin = !!document.getElementById('logout-form');
    store.commit('handleLoginStatus', isLogin);
    next();
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
