<template>
    <div id="app">
        <Header />
        <router-view/>
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';


export default {
    name: 'app',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            isLogin: false,
        };
    },
    methods: {

    },
};
</script>


<style lang="scss">
@import "~@/scss/reset";
@import "~@/scss/layout";
@import "~@/scss/btn";
</style>
