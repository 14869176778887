<template>
    <header>
        <div class="container">
            <div class="logo_group">
                <div class="logo">
                    <router-link to="/">
                        <img
                         title="logo"
                         alt="KKBOX"
                         src="@/assets/img/png/logo-kkpoints-s.png">
                        <h1>點數中心</h1>
                    </router-link>
                </div>
                <div class="mobile_login">
                    <div
                     class="login_button"
                     @click="login()">
                        {{isLogin ? '登出' : '登入'}}
                    </div>
                </div>
            </div>
            <hr />
            <nav>
                <ul class="nav_link_group">
                    <li class="nav_link" v-for="(navLink, index) in navLinkList" :key="index">
                        <router-link :to="navLink.url" active-class="active_link" exact>
                            {{navLink.title}}
                        </router-link>
                    </li>
                    <li class="nav_link desktop_login">
                        <div class="login_button" @click="login()">
                            {{isLogin ? '登出' : '登入'}}
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
    name: 'HeaderComponent',
    data() {
        return {
            navLinkList: [
                {
                    title: '點數說明',
                    url: this.$router.resolve({
                        name: 'info',
                        params: {},
                    }).href,
                },
                {
                    title: '查詢點數',
                    url: this.$router.resolve({
                        name: 'record',
                        params: {},
                    }).href,
                },
                {
                    title: '點數活動',
                    url: this.$router.resolve({
                        name: 'event',
                        params: {},
                    }).href,
                },
            ],
        };
    },
    methods: {
        login() {
            if (this.isLogin) {
                document.getElementById('logout-form').submit();
            } else {
                document.getElementById('redirect-path').value = window.location.href;
                document.getElementById('login-form').submit();
            }
        },
    },
    mounted() {
        const isLogin = !!document.getElementById('logout-form');
        this.$store.commit('handleLoginStatus', isLogin);
    },
    computed: mapState([
        'isLogin',
    ]),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/scss/_mixins";

header {
    padding: 13px 0 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
    background: #fff;
    @include for-md-mobile {
        box-shadow: none;
        border-bottom: 0.5px solid rgba(51, 51, 51, 0.2);
    }
    .container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .logo_group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo {
                img {
                    height: 43.4px;
                    float: left;
                    @include for-md-mobile {
                        height: 31px;
                    }
                }
                h1 {
                    letter-spacing: 1px;
                    color: #00aed8;
                    font-size: 18px;
                    float: left;
                    padding: 0px 10px;
                    margin: 10px 0 10px -20px;
                    border-left: 1px solid #00aed8;
                    @include for-md-mobile {
                       font-size: 13px;
                       margin: 7px -12px;
                    }
                }
            }
            .mobile_login {
                display: block;
                font-size: 14px;
                cursor: pointer;
                margin-right: 17px;
                @include for-md-desktop {
                    display: none;
                }
            }
            @include for-md-desktop {
                border: none;
            }
        }
        hr {
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            top: 75px;
            border-width: 0.5px;
            display: block;
            margin: 0;
            @include for-md-desktop {
                display: none;
            }
            @include for-md-mobile {
                box-shadow: inset 0 2px 4px rgba(0,0,0,.07);
                top: 60px;
                height: 6px;
                border: 0;
            }
        }
        nav {
            margin-top: 20px;
            ul.nav_link_group {
                display: flex;
                justify-content: space-around;
                li.nav_link {
                    margin: 13px 0;
                    a, .login_button {
                        color: #333333;
                        font-size: 18px;
                        padding: 0 35px;
                        cursor: pointer;
                        &.active_link {
                            color: #00aed8;
                        }
                        &:hover {
                            color: #00aed8;
                        }
                        @include for-md-mobile {
                            font-size: 15px;
                            padding: 0;
                        }
                    }
                    .login_button {
                        cursor: pointer;
                        border-left: 1px solid #333333;
                    }
                    &.desktop_login {
                        display: none;
                        @include for-md-desktop {
                            display: list-item;;
                        }
                    }
                }
            }
            @include for-md-desktop {
                margin-top: 0px;
            }
        }
        @include for-md-desktop {
            flex-direction: row;
        }
    }
    @include for-md-desktop {
        padding-bottom: 18px;
    }
}
</style>
