import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLogin: false,
    },
    mutations: {
        handleLoginStatus(state, status) {
            const newState = {
                isLogin: status,
            };
            Object.assign(state, newState);
        },
    },
    actions: {

    },
});
