<template>
    <div class="billboard" :class="[view, { logined: isLogin }]">
        <img
         class="kkpoints_logo_event"
         src="@/assets/img/png/logo-kkpoints-255-wt@3x.png"
         alt="kkpoints logo">
        <img
         v-if="userData && userData.avatar"
         class="avatar"
         :src="userData.avatar"
         alt="avatar">
        <div class="member_data">
            <p v-if="userData && userData.id">{{userData.id}}</p>
            <p v-if="userData && userData.membership_name">{{userData.membership_name}}</p>
        </div>
        <div class="white_bottom">
            <img
             class="kkpoints_icon"
             src="@/assets/img/png/logo-kkpoints-s.png"
             alt="kkpoints icon">
            <p class="kkpoints_count">
                {{myPoints}}
            </p>
            <div v-show="isLoading" class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
            </div>
            <img
                class="kkpoints_logo_info"
                src="@/assets/img/png/logo-kkpoints.png"
                alt="kkpoints logo">
            <h2>娛樂 All in One  累點精彩換</h2>
            <h4>KKBOX 全面啟動 KK Points 點數服務。</h4>
            <h4>開始使用 KK Points 參加各種抽獎、限定優惠、兌換指定商品等活動！</h4>
            <div class="login_button" @click="login()">登入查看點數</div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
    name: 'BillboardComponent',
    props: {
        view: String,
        userData: Object,
        myPoints: Number,
        isLoading: Boolean,
    },
    data() {
        return {};
    },
    methods: {
        login() {
            if (!this.isLogin) {
                document.getElementById('redirect-path').value = window.location.href;
                document.getElementById('login-form').submit();
            }
        },
    },
    computed: mapState([
        'isLogin',
    ]),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/scss/_mixins";

.billboard {
    width: 100%;
    background: #00aed8;
    /* Old browsers */
    background: -moz-linear-gradient(top,  #00aed8 0%, #5ef0ff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #00aed8 0%, #5ef0ff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #00aed8 0%, #5ef0ff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(
    startColorstr='#00aed8', endColorstr='#5ef0ff',GradientType=0
    );
    /* IE6-9 */
    padding: 45px 0 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    height: 262px;
    @include for-md-mobile {
        padding: 30px 0 113px;
        height: 193px;
    }

    .avatar {
        height: 65px;
        border-radius: 37.5px;
        box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.1);
        margin-right: 15px;
        display: none;
        @include for-md-mobile {
            height: 50px;
            margin-right: 12px;
        }
    }
    .member_data {
        display: none;
        p {
            text-align: left;
            font-size: 16px;
            font-weight: 300;
            &:first-child {
                font-size: 18px;
                font-weight: 400;
            }
        }
        @include for-md-mobile {
            p{
                font-size: 12px;
                &:first-child {
                    font-size: 14px;
                }
            }
        }
    }
    img.kkpoints_logo_event, img.kkpoints_logo_info {
        display: none;
    }
    .white_bottom {
        height: 110px;
        width: 100%;
        position: absolute;
        bottom: -1px;
        text-align: center;
        background-image: url("../assets/img/svg/wt-d.svg");
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        padding-top: 50px;
        filter: drop-shadow(0px -5px 15px rgba(255,255,255,0.4));
        img.kkpoints_icon {
            height: 25px;
            display: none;
        }
        p.kkpoints_count {
            display: none;
            color: #00aed8;
            font-size: 50px;
            font-weight: bold;
            line-height: 1.08;
            margin-top: 5px;
        }
        h2 {
            text-align: center;
            color: #000000;
            line-height: 1.84;
            font-size: 25px;
            font-weight: 600;
            & + h4 {
              margin-top: 0px;
              @include for-md-mobile {
                  margin-top: 0px;
              }
            }
        }
        h4 {
            text-align: center;
            color: #000000;
            line-height: 1.31;
            font-size: 16px;
            margin: 0px auto 0px;
            font-weight: 300;
            max-width: 293px;
            &:last-child {
                margin-top: 0px;
            }
        }
        .login_button {
            text-align: center;
            color: #ffffff;
            font-size: 16px;
            font-weight: 500;
            width: 126px;
            height: 41px;
            border-radius: 22px;
            border-color: #00aed8;
            background-color: #00aed8;
            cursor: pointer;
            display: inline-block;
            line-height: 41px;
            transition: box-shadow .5s;
            &:hover {
                box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
            }
        }
        @include for-md-mobile {
            background-image: url("../assets/img/svg/wt-m.svg");
            padding-top: 30px;
            height: 92px;
            h2 {
                font-size: 32px;
            }
            h4 {
                font-size: 16px;
                padding: 0 26px;
            }
            img.kkpoints_logo {
                height: 32px;
            }
            .login_button {
               font-size: 14px;
               width: 108px;
               height: 34px;
                   line-height: 34px;
            }
            img.kkpoints_icon {
                height: 16px;
            }
            p.kkpoints_count {
                font-size: 36px;
            }
        }
    }
    &.info_view {
        background-image: url("../assets/img/png/bg.png");
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        padding: 0;
        height: auto;
        flex-direction: column;
        .white_bottom {
            position: relative;
            bottom: 0;
            width: 450px;
            height: 450px;
            border-radius: 250px;
            padding-top: 33px;
            margin: 35px 0;
            background-color: #ffffff;
            filter: none;
            .kkpoints_logo_info {
                height: 191px;
                display: block;
                margin: 0 auto 32px;
                @include for-md-mobile {
                    height: 145px;
                }
                @include for-xs-mobile {
                 height: 124px;
                 margin: 0 auto 15px;
                }
            }
            @include for-md-mobile {
              width: 337px;
                height: 337px;
                margin: 35px 0;
            }
            @include for-xs-mobile {
            width: 300px;
            height: 300px;
            }
        }
        .login_button {
            display: none;
        }
        @include for-md-mobile {
            background-image: url("../assets/img/png/bg.png");
            h2 {
                font-size: 19px;
            }
            h4 {
                font-size: 13px;
            }
        }
    }
    &.event_view {
        h2, h4 {
            display: none;
        }
        img.kkpoints_logo_event {
            display: block;
            height: 48px;
        }
        &.logined {
            .kkpoints_logo_event, .login_button {
                display: none;
            }
            img.avatar, .member_data, img.kkpoints_icon, p.kkpoints_count {
                display: block;
            }
            img.kkpoints_icon {
                margin: 0 auto;
            }
        }
    }
    &.record_view {
        h2, h4, .kkpoints_logo, .login_button {
            display: none;
        }
        img.avatar, .member_data, img.kkpoints_icon, p.kkpoints_count {
            display: block;
        }
        img.kkpoints_icon {
            margin: 0 auto;
        }
        &.logined {
            .kkpoints_logo, .login_button {
                display: none;
            }
            img.avatar, .member_data, img.kkpoints_icon, p.kkpoints_count {
                display: block;
            }
            img.kkpoints_icon {
                margin: 0 auto;
            }
        }
    }
}
</style>
