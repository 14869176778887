import Vue from 'vue';
import Router from 'vue-router';
import Info from './views/Info.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: '/info',
        },
        {
            path: '/info',
            name: 'info',
            component: Info,
        },
        {
            path: '/record',
            name: 'record',
            component: () => import(/* webpackChunkName: "record" */ './views/Record.vue'),
        },
        {
            path: '/event',
            name: 'event',
            component: () => import(/* webpackChunkName: "event" */ './views/Event.vue'),
        },
        {
            path: '/events/:event_id',
            name: 'event_redeem',
            component: () => import(/* webpackChunkName: "event_redeem" */ './views/EventRedeem.vue'),
        },
        {
            path: '/hifi/:event_id',
            name: 'hifi',
            component: () => import(/* webpackChunkName: "hifi" */ './views/Hifi.vue'),
        },
    ],
});
